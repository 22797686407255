.react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
  background-color: $primary !important;
  &:hover {
    background-color: rgba($primary, 0.8) !important;
  }
}

.react-datepicker__day--in-selecting-range, .react-datepicker__day--selecting-range-start {
  background-color: rgba($primary, 0.4) !important;
  &:hover {
    background-color: rgba($primary, 0.6) !important;
  }
}