@import "pages/global";
@import "pages/main";
@import "pages/font";

%size-flags {
  max-height: 25px;
  max-width: 30%;
}

.img-flags {
  @extend %size-flags;
}

.img-flags-deactivated {
  @extend %size-flags;
  opacity: 0.5;
}

.tox-notifications-container {
  display: none !important;
}