.nav-item {
  // Customize Dropdown Arrows for Navbar
  &.dropdown {
    .dropdown-toggle {
      &::after {
        text-align: center;
        float: right;
        vertical-align: 0;
        border: 0;
        font-weight: 900;
        content: '';
      }
    }

    &.show {
      .dropdown-toggle::after {
        content: '';
      }
    }
  }

  // Counter for nav links and nav link image sizing
  .nav-link {
    position: relative;

    .badge-counter {
      position: absolute;
      transform: scale(0.7);
      transform-origin: top right;
      right: .25rem;
      margin-top: -.25rem;
    }

    .img-profile {
      height: 2rem;
      width: 2rem;
    }
  }
}
