html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

a {
  &:focus {
    outline: none;
  }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}
