#wrapper {
  display: flex;

  #content-wrapper {
    background-color: $gray-100;
    width: 100%;
    overflow-x: hidden;

    #content {
      flex: 1 0 auto;
    }
  }
}

.infoDialog {
  opacity: 40%;
  margin-left: 7px;
}

.infoDialog:hover {
  opacity: 100%;
}

.infoDisabledButton {
  filter: brightness(1.2);
}

.paginationPage {
  ul{
    flex-wrap: wrap;
  }
}