@font-face {
  font-family: "GreatVibes";
  src: url("../../../public/fonts/greatvibes-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "JosefineSans";
  src: url("../../../public/fonts/josefinsans-variablefont_wght-webfont.woff") format("woff");
}

@font-face {
  font-family: "Lora";
  src: url("../../../public/fonts/lora-variablefont_wght-webfont.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/montserrat-variablefont_wght-webfont.woff") format("woff");
}

@font-face {
  font-family: "OpenSans";
  src: url("../../../public/fonts/opensans-variablefont_wdthwght-webfont.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  src: url("../../../public/fonts/Roboto-Regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Bitter";
  src: url("../../../public/fonts/Bitter-Regular.woff") format("woff");
}

@font-face {
  font-family: "Arial";
  src: url("../../../public/fonts/ArialMT.woff") format("woff");
}

@font-face {
  font-family: "Consolas";
  src: url("../../../public/fonts/Consolas.woff") format("woff");
}

@font-face {
  font-family: "Calibri";
  src: url("../../../public/fonts/Calibri.woff") format("woff");
}

@font-face {
  font-family: "Cambria";
  src: url("../../../public/fonts/Cambria.woff") format("woff");
}

@font-face {
  font-family: "Centaur";
  src: url("../../../public/fonts/CentaurMTStd.woff") format("woff");
}

@font-face {
  font-family: "SansSerif";
  src: url("../../../public/fonts/MicrosoftSansSerif.woff") format("woff");
}

.font-family-roboto {
  font-family: "Roboto";
}

.font-family-bitter {
  font-family: "Bitter";
}

.font-family-arial {
  font-family: "Arial";
}

.font-family-consolas {
  font-family: "Consolas";
}

.font-family-calibri {
  font-family: "Calibri";
}

.font-family-cambria {
  font-family: "Cambria";
}

.font-family-centaur {
  font-family: "Centaur";
}

.font-family-sans-serif {
  font-family: "sans-serif";
}

.font-family-great-vibes {
  font-family: "GreatVibes";
}

.font-family-josefine-sans {
  font-family: "JosefineSans";
}

.font-family-lora {
  font-family: "Lora";
}

.font-family-montserrat {
  font-family: "Montserrat";
}

.font-family-open-sans {
  font-family: "OpenSans";
}